/* CSS file for contact page */

.contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.contact h3 {
  font-size: 2.5rem;
  margin-bottom: 5%;
  margin-left: 5.5%;
  border-bottom: 5px solid var(--dark-blue);
  width: 500px;
}

.contactPersonsList {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.contactInfoPage {
  font-size: 1.5rem;
  width: 350px;
}

.contactObject {
  transform: rotate(145deg);
  height: 1000px;
  right: 0;
  border-right: 5px solid var(--yellow);
  border-left: none;
}

@media (max-width: 1000px) {
  .contact {
    position: relative;
    display: block;
    overflow-y: hidden;
    /* width: fit-content; */
  }

  .contact h3 {
    width: 70%;
    margin-left: 60px;
    margin-top: 120px;
  }

  .contactInfoPage {
    text-align: center;
    margin: 20px 0 30px;
  }

  .contactPersonsList {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 100px;
  }
}
