/* CSS file for Homepage */

/* Main */
.homepage {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.homepageWrapper {
  width: 60%;
  padding-top: 15%;
  margin-left: 15%;
}

/* Header */
.homepageHeader h1 {
  font-size: 2.5rem;
  border-bottom: 7px solid var(--dark-blue);
}

.homepageHeader p {
  font-size: 1.5rem;
}

/* About */
.about {
  margin-top: 40px;
}

.about h2 {
  font-size: 2rem;
  width: 40%;
  border-bottom: 5px solid var(--light-blue);
  margin-bottom: 20px;
}

.aboutDesc {
  width: 60%;
  font-size: 1.05rem;
}

/* Background Image */
.backgroundImage {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -10;
  opacity: 0.3;
}

@media (max-width: 1000px) {
  .homepage {
    width: auto;
    text-align: right;
  }

  .homepageWrapper {
    width: fit-content;
    height: fit-content;
    margin: 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about h2 {
    text-align: right;
    width: 80%;
    margin-left: 20%;
  }

  .aboutDesc {
    width: auto;
    text-align: justify;
  }

  .homepageObject {
    width: 150px;
    left: 0;
    top: -15px;
  }
}
