/* CSS file for teams display */
.teamsDisplay {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.teamsDisplay h2 {
  position: absolute;
  top: 100px;
  left: 200px;
}

.teamAndElementSelect {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 10%;
  right: 5%;
}

.teamAndElementSelect label {
  font-size: 22px;
}

.teamAndElementSelect select {
  border: none;
  background-color: var(--light-blue);
  color: white;
  padding: 5px 10px;
  width: 200px;
  text-align: center;
  border-radius: 5px;
}

.teamAndElementSelect select:hover {
  background-color: var(--dark-blue);
}

.displayResults {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

/* Buttons */
.teamDisplayButtons > button {
  margin: 10px;
  width: 130px;
  border: 3px solid var(--dark-blue);
  padding: 5px;
  font-family: "Oswald", sans-serif;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.teamDisplayButtons > button:hover {
  border-color: var(--light-blue);
}

.teamDisplayButtons > button:active {
  transform: scale(0.97);
}

@media (max-width: 1000px) {
  .teamAndElementSelect {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .teamsDisplay h2 {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

  .displayResults {
    top: 30%;
    width: 90%;
  }
}
