/* CSS file for shop page */

.shop {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.shop h2 {
  border-bottom: 3px solid var(--yellow);
  width: 70%;
  margin-bottom: 5px;
  text-align: center;
}

.shopPageInfo {
  margin-bottom: 20px;
  text-align: left;
  width: 70%;
}

.shopItems {
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  overflow: scroll;
}

@media (max-width: 1000px) {
  .shopPageInfo {
    width: 60%;
  }

  .shop h2 {
    width: auto;
  }

  .shopPageInfo {
    font-size: 12px;
    /* border-bottom: 1px solid var(--dark-blue); */
  }

  .shopItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
  }
}
