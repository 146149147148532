/* CSS file for contact person's */

h6 {
  font-size: 2rem;
}

.contactInfo {
  position: relative;
  display: flex;
  margin: 10px 0;
  /* width: 330px; */
}

.contactInfo p {
  font-size: 1.5rem;
}

.contactInfo a {
  font-weight: bold;
}

.contactInfo:nth-child(3) {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--light-blue);
}

.icon {
  opacity: 0.4;
  margin-right: 10px;
  font-size: 1.75rem;
  color: var(--dark-blue);
}

@media (max-width: 1000px) {
  .contactInfo .contactPerson h6 {
    margin-top: 20px;
  }
}
