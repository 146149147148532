/* CSS file for events section */

.getInvolved {
  position: relative;
  padding-top: 380px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.getInvolved h2 {
  text-align: right;
  margin-top: 25%;
  margin-left: 20%;
  margin-bottom: 30px;
  font-size: 2rem;
  width: 50%;
  border-bottom: 5px solid var(--dark-blue);
}

.getInvolved h3 {
  font-size: 26px;
  color: var(--dark-blue);
  text-decoration: underline;
  margin-bottom: 20px;
}

.getInvolved h4 {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.getInvolvedVolunteer,
.getInvolvedSponsor,
.getInvolvedKids {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 30px;
}

.getInvolvedKids,
.getInvolvedVolunteer {
  border-bottom: 2px solid var(--yellow);
}

.teamsContainer {
  margin-top: 20px;
}

.border {
  padding-bottom: 30px;
}

.listsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.getInvolved a {
  text-align: center;
  width: 80%;
  border: 1px solid var(--light-blue);
  padding: 10px 30px;
  margin: 20px 0;
}

/* Background Object */
.getInvolvedObject {
  top: -25px;
  left: 0;
  transform: rotate(-60deg);
}

@media (max-width: 1000px) {
  .getInvolved {
    padding-top: 0;
    display: block;
    /* height: fit-content; */
    overflow: scroll;
  }

  .getInvolved h2 {
    width: 70%;
  }

  .getInvolved h4 {
    width: fit-content;
  }

  .getInvolvedVolunteer,
  .getInvolvedSponsor {
    width: fit-content;
    align-items: left;
    padding-left: 15%;
  }

  .getInvolvedKids {
    width: fit-content;
    margin-left: 50px;
  }

  .getInvolved a {
    /* margin-left: 20%; */
    width: fit-content;
    align-self: center;
    margin-bottom: 100px;

    /* transform: translateX(-50%); */
  }

  .getInvolvedObject {
    height: 500px;
  }
}
