/* CSS for NavBar */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--light-blue);
  border-bottom: 10px solid var(--dark-blue);
  color: var(--off-white);
  box-shadow: var(--box-shadow);
  z-index: 1;
}

/* Logo */
.logo {
  position: absolute;
  width: 110px;
  top: 5px;
  left: 40px;
}

/* NavBar Links */
.navContainer {
  display: flex;
  justify-content: space-between;
}

.navLinks ul {
  display: flex;
  margin-right: 30px;
}

.navLinks li {
  margin: 15px;
  /* color: white; */
}

.navLinks a {
  color: white;
}

.navLinks a:hover {
  border-bottom: 2px solid var(--yellow);
}

.dropdownList {
  visibility: hidden;
  position: absolute;
  /* left: 60%; */
  /* width: 400px; */
  /* background: linear-gradient(
    to bottom,
    var(--light-blue) 27%,
    var(--dark-blue) 27% 100%
  ); */
  background: var(--dark-blue);
  /* border-left: 1px solid var(--yellow);
  border-right: 1px solid var(--yellow); */
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-around;
  /* flex-direction: column; */
}

.dropdownList Link {
  visibility: hidden;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdown:hover {
  .dropdownList,
  .dropdownList Link {
    visibility: visible;
  }
}

/* Burger Menu */
.burgerMenuBtn {
  cursor: pointer;
  display: none;
  font-size: 1.5rem;
}

@media (max-width: 1000px) {
  .logo {
    width: 90px;
    margin-top: 10px;
  }

  .burgerMenuBtn {
    width: 100%;
    text-align: right;
    display: block;
    margin: 10px 20px;
  }

  .navLinks ul {
    display: none;
  }

  .navLinks.open .list {
    animation: slideIn 0.4s ease-in;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 55px;
    right: 0;
    background-color: var(--dark-blue);
    width: 50%;
    z-index: 1;
    margin: 0;
    padding-bottom: 30px;
    border-bottom-left-radius: 20px;
  }

  .navLinks.open {
    display: flex;
  }

  .listItem {
    margin: 0;
    padding: 10px;
  }

  .navLinks.open .dropdownList {
    display: flex;
    flex-direction: column;
    position: absolute;
    /* top: 55px; */
    border: none;
    right: 0;
    background-color: var(--yellow);
    width: 50%;
    z-index: 1;
    margin: 0;
    border-bottom-left-radius: 20px;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(400px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
