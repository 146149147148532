/* CSS file for Footer */

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  /* height: 100px; */
}

.footerLinks a {
  font-size: 2rem;
  margin: 5px;
  color: var(--dark-blue);
}

.footerLinks a:hover {
  color: var(--light-blue);
}

.footerInfo {
  text-align: right;
  margin-right: 20px;
  font-size: 0.75rem;
}

.footerInfo p {
  font-family: "Oswald", sans-serif;
}

@media (max-width: 1000px) {
  .footer {
    background-color: var(--dark-blue);
    color: white;
    align-items: center;
    height: 50px;
    /* width: 100%; */
  }

  .footerLinks a {
    color: white;
    font-size: 1.25rem;
  }

  .footerInfo {
    font-size: 0.5rem;
  }
}
