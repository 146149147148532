/* CSS file for sponsors page */

.sponsors {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sponsors h3 {
  font-size: 2rem;
  width: 40%;
  border-bottom: 5px solid var(--light-blue);
}

.sponsorsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.sponsorsObject {
  transform: rotate(200deg);
  left: 0;
  height: 1000px;
}

@media (max-width: 1000px) {
  .sponsors {
    display: block;
    overflow-y: scroll;
  }

  .sponsors h3 {
    margin-top: 120px;
    width: 80%;
    margin-left: 20px;
    font-size: 1.75rem;
  }

  .sponsorsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
  }
}
