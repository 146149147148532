/* CSS file for news section */

.news {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll;
}

.news h3 {
  position: absolute;
  right: 20%;
  text-align: right;
  font-size: 2rem;
  width: 40%;
  margin-bottom: 20px;
}

.newsList {
  position: absolute;
  top: 100px;
  left: 25%;
}

.tweetContainer {
  border: "2px solid grey";
  border-radius: "10px";
  width: 400px;
  margin: 10px 0;
}

/* Background Object */
.newsObject {
  top: -20px;
  height: 550px;
}

@media (max-width: 1000px) {
  .news {
    position: relative;
    display: block;
    /* height: fit-content; */
    /* align-items: center; */
    overflow-y: scroll;
  }

  .news h3 {
    top: 15%;
    width: 70%;
    border-bottom: 3px solid var(--dark-blue);
  }

  .newsList {
    margin-top: 130px;
    margin-bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tweetContainer {
    width: 90%;
  }
}
