/* CSS file for teams */

.active {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 100vh;
}

/* Table */
.table h3 {
  margin-bottom: 10px;
}

/* Results/Fixtures */

.resultsCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0;
  border: 5px solid var(--light-blue);
  height: 220px;
  width: 500px;
}

.resultFixtures {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.5rem;
  font-weight: bolder;
  font-style: italic;
}

.teamNames {
  text-align: center;
  width: 150px;
}

.vs {
  border: 5px solid var(--light-blue);
  border-radius: 50%;
  width: 60px;
  color: var(--yellow);
  text-align: center;
  padding: 10px;
  font-style: normal;
  background-color: var(--dark-blue);
}

.vs::before {
  content: "";
  position: absolute;
  height: 215px;
  width: 5px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
  background-color: var(--light-blue);
}

.fixture {
  font-weight: 400;
}

.teamBackground {
  position: fixed;
  width: 500px;
  height: 220px;
  z-index: -10;
  opacity: 0.2;
}

@media (max-width: 1000px) {
  .active {
    position: relative;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 100px;
    padding-bottom: 110px;
  }

  .table h3 {
    text-align: right;
  }

  .resultFixtures {
    padding-top: 10px;
    width: fit-content;
    text-align: center;
  }

  .resultsCard {
    width: auto;
    margin: 5px 10px;
  }

  .backgroundImage {
    display: none;
  }
}

@media (max-width: 385px) {
  .active {
    /* width: fit-content; */
    padding-bottom: 200px;
  }
  .table {
    margin-top: 150px;
  }
}
