/* Variables */
:root {
  --light-blue: #38b6ff;
  --dark-blue: #120554;
  --yellow: #fbe748;
  --off-white: #f4f4f4;
  --box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5),
    10px 10px 15px rgba(0, 0, 0, 0.3);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--off-white);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Utilities */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.3);
}

p {
  font-family: "Roboto", sans-serif;
}

li {
  list-style-type: none;
  font-family: "Oswald", sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

/* Background Object */
.homepageObject {
  position: absolute;
  right: 100px;
  width: 400px;
  height: 100vh;
  background: linear-gradient(var(--light-blue), transparent);
  opacity: 0.8;
  z-index: -1;
  transform: rotate(10deg);
  border-left: 5px solid var(--yellow);
}
